import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import ActiveChargeCard from './ActiveChargeCard';  // Import your card component
import { AppDispatch, RootState } from '../store';  // Adjust the import path to your store types
import { useHeaderHeight } from '@react-navigation/elements'
import { getActiveCharges } from '../store/actions/user-actions';
import { ActiveChargeAttributes } from '../models/active-charges-model';
import { updateSelectedCharger } from '../store/actions/charging-actions';

interface Props {
    children: React.ReactNode;  // Typing for the children prop
    currentRouteName: string;
    offset?: number;
}

const ActiveChargeWrapper: React.FC<Props> = ({ 
    children, 
    currentRouteName, 
    offset = 0 }) => {
  const dispatch: AppDispatch = useDispatch();
  // Use useSelector to access activeCharges from the Redux store
  const globalActiveCharges: ActiveChargeAttributes[] | null = useSelector((state: RootState) => state.user.activeCharges);
  const pollTimer = useRef<NodeJS.Timeout | null>(null);
  const [activeChargerId, setActiveChargerId] = useState<string>('');
  
  let headerHeight: number;
  try {
    headerHeight = useHeaderHeight();  // Use header height dynamically
  } catch (error) {
    //console.warn("Could not find header height, using fallback value.");
    headerHeight = 10;  // Fallback value (adjust as needed)
  }

  const pollAndUpdate = async () => {
    dispatch(getActiveCharges()); // Dispatch to get active charges
  };

   // Start polling based on localActiveCharges state
  useEffect(() => {
    pollAndUpdate(); // Initial call
  }, []);

  // Update local state when global activeCharges changes
  useEffect(() => {
    // Start polling when there are active charges
    if (globalActiveCharges && globalActiveCharges.length > 0) {
      setActiveChargerId(globalActiveCharges[0]?.charger.uuid ?? '');
      pollAndUpdate(); // Initial call
      pollTimer.current = setInterval(pollAndUpdate, 5000); // Poll every 5 seconds
    } else {
      if(activeChargerId){
        dispatch(updateSelectedCharger(activeChargerId));
      }
      if (pollTimer.current) {
        clearInterval(pollTimer.current);
        pollTimer.current = null;
      }
    }

    return () => {
      // Cleanup when component unmounts or polling needs to stop
      if (pollTimer.current) {
        clearInterval(pollTimer.current);
        pollTimer.current = null;
      }
    };
  }, [globalActiveCharges]);


  return (
    <View style={styles.wrapper}>
      {children}

      {/* Conditionally render the ActiveChargeCard if there are active charges */}
      <View style={[styles.chargeCardContainer, { top: headerHeight + offset }]}>
        {globalActiveCharges && globalActiveCharges.length > 0 && currentRouteName !== 'ChargingScreen' && currentRouteName !== 'ChargeSummary' && (
          <ActiveChargeCard />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    position: 'relative',
  },
  chargeCardContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: 10,  // Adjust as needed
    zIndex: 1000,  // Ensure it appears above other components
  },
});

export default ActiveChargeWrapper;