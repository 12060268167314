import React, { useState, MutableRefObject, useRef, useEffect } from 'react';

import { SafeAreaView, View,Text, ScrollView,
     StyleSheet, Dimensions, TextInput, Image,
      Pressable, Linking } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import RBSheet from "react-native-raw-bottom-sheet";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from 'react-redux';
import { UserAttributes } from '../models/login-register-model';
import { RootState } from '../store';
import { clearSession } from '../store/actions/user-session';
import { UserSessionAttributes } from '../models/user-session-model';
import { AppDispatch } from '../store';
import SignOutOptions from './SignOutOptions';
import SignInOptions from './SignInOptions';
import UserModeSelect from '../components/UserModeSelect';

const { width, height } = Dimensions.get('window');
// Define the type of your popup sheet ref
interface PopupSheetRef {
    open: () => void;
    close: () => void;
}

const MyAccount = ({ navigation }) => {
    //const user:UserAttributes = useSelector((state: RootState) => state.user.userObj);
    const userSession:UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
    const dispatch: AppDispatch = useDispatch();
    //console.log("Stored state user:", user);
    //const navigation = useNavigation();

    const handleLogout = async () => {
        dispatch(clearSession(userSession));

        await AsyncStorage.clear();

        const getToken = await AsyncStorage.getItem('token')
        
        navigation.navigate('WelcomeScreen', {
            token: getToken
        })
    }

    const refRBSheet = useRef<PopupSheetRef>();
    const refSigninSheet = useRef<PopupSheetRef>();

    const OpenSigninSheet = () => {
        if(refSigninSheet.current) {
            refSigninSheet.current.open();
        }
    }

    const CloseSigninSheet = () => {
        if(refSigninSheet.current) {
            refSigninSheet.current.close();
        }
    }

    const OpenBottomSheet = () => {
        if(refRBSheet.current){
            refRBSheet.current.open();
        }
      }
    
      const CloseBottomSheet = () => {
        if(refRBSheet.current){
            refRBSheet.current.close();
        }
      }

    useEffect(() => {
        console.log(userSession);
    }, [userSession]);

    const openMeanderWebsite = () => {
        Linking.openURL('https://www.meanderev.com');
    };
    
    return(
        <SafeAreaView>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 60}]}>
                <ScrollView>
                    <View className='p-4'>
                        <View className='flex items-center mt-6'>
                            <Image 
                                source={require('../../assets/meanderlogowhite_120x120.png')}
                                style={{ width: 100, height: 100, alignItems: 'center' }}
                            />
                            <Text className='text-lg font-semibold text-center text-white'>
                                {`${userSession?.first_name ?? 'Guest'} ${userSession?.role.toLowerCase() === 'guest' ? '\n' : ''} ${userSession?.last_name ?? ''}`}
                            </Text>
                            {userSession?.role.toLowerCase() !== 'guest' &&
                                <Text className='text-sm font-normal text-c enter' style={styles.textColor}>{`${userSession?.email ?? ''}`}</Text>
                            }
                        </View>
                        { (userSession && userSession?.role === 'guest') ?
                            <View style={styles.line} className="my-4"/> :
                            <View style={{ marginHorizontal: -16 }}>
                                <UserModeSelect />
                            </View>
                        }
                        <View>
                            { userSession && userSession?.role !== 'guest' && userSession?.email !== '' &&
                                <Pressable
                                    onPress={() => navigation.navigate("AccountInfo")}
                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                                >
                                <View className='flex flex-row items-center justify-between my-4'>
                                    <View className='flex flex-row items-center gap-3'>
                                        <Image 
                                        source={require('../../assets/AccountIcon.png')}
                                        style={{ width: 20, height: 20 }}
                                        />
                                        <Text className='text-base font-medium text-white'>Update Account Information</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                                </Pressable>
                            }
                            { userSession && userSession?.role !== 'guest' && userSession?.email !== '' &&
                                <Pressable
                                    onPress={() => navigation.navigate("TransactionHistory")}
                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                                >
                                <View className='flex flex-row items-center justify-between my-4'>
                                    <View className='flex flex-row items-center gap-3'>
                                        <Image 
                                        source={require('../../assets/transactionIcon.png')}
                                        style={{ width: 20, height: 20 }}
                                        />
                                        <Text className='text-base font-medium text-white'>Transaction History</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                                </Pressable>
                            }
                            { userSession && userSession?.role !== 'guest' && userSession?.email !== '' &&
                                <Pressable onPress={()=> navigation.navigate('AccountInfo',{
                                        changePassword: true
                                    })}
                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                                >
                                    <View className='flex flex-row items-center justify-between my-4'>
                                        <View className='flex flex-row items-center gap-3'>
                                            <Image 
                                            source={require('../../assets/LockIcon.png')}
                                            style={{ width: 17, height: 20 }}
                                            />
                                            <Text className='text-base font-medium text-white'>Change Password</Text>
                                        </View>
                                        <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                    </View>
                                </Pressable>
                            }
                            <Pressable 
                                onPress={openMeanderWebsite}
                                style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                            >
                            <View className='flex flex-row items-center justify-between my-4'>
                                <View className='flex flex-row items-center gap-3'>
                                    <Image 
                                        source={require('../../assets/infoIcon.png')}
                                        style={{ width: 20, height: 20 }}
                                    />
                                    <Text className='text-base font-medium text-white'>About</Text>
                                </View>
                                <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                            </View>
                            </Pressable>
                            <Pressable 
                                onPress={OpenBottomSheet}
                                style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                            >
                                <View className='flex flex-row items-center justify-between my-4'>
                                    <View className='flex flex-row items-center gap-3'>
                                        <Image 
                                        source={require('../../assets/Logout.png')}
                                        style={{ width: 20, height: 20 }}
                                        />
                                        <Text className='text-base font-medium text-white'>Logout</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                            </Pressable>
                            { userSession && (userSession?.role === 'guest' || userSession?.email === '') &&
                            <Pressable 
                                onPress={OpenSigninSheet}
                                style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1}]}
                            >
                                <View className='flex flex-row items-center justify-between my-4'>
                                    <View className='flex flex-row items-center gap-3'>
                                        <Image 
                                        source={require('../../assets/lightning.png')}
                                        style={{ width: 20, height: 20 }}
                                        />
                                        <Text className='text-base font-medium text-white'>Login</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                            </Pressable>
                            }
                        </View>
                    </View>
                </ScrollView>
                <SignOutOptions
                    navigation={navigation}
                    refRBSheet={refRBSheet}
                    destination={'WelcomeScreen'}
                />
                <SignInOptions
                    navigation={navigation}
                    refRBSheet={refSigninSheet}
                    destination={'MyAccount'}
                />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    bgColor: {
        backgroundColor: '#F75555'
    },
    textColor: {
        color: '#8D8E90'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
    },
})

export default MyAccount;