import React, { useEffect } from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  StyleSheet,
} from "react-native";
import { FontAwesome6, FontAwesome5, FontAwesome } from '@expo/vector-icons';
import { ChargerAttributes } from "../models/charger-model";
import LoadingPlaceholder from "./LoadingPlaceholder";
import DirectionsButton from "./DirectionsButton";
import { generatePeakObj } from "./common/utils/peak-off-peak-field";
import { getPriceInfo } from "./common/utils/get-price-info";

interface TimeAndDistance {
    chargerId: string;
    myDistance: string;
    myTime: string;
  }

const ChargerSelectSinglePopup = (
    props: {
        selectedChargers: ChargerAttributes[],
        myTimeAndDistance: TimeAndDistance[],
        handleViewCharger: (charger: ChargerAttributes, index: number) => void,
    }
) => {

    const { currentPrice, priceLabel, nextTransition, todayPeak } = getPriceInfo(props.selectedChargers[0]);

    return(
        <View>
          {/* Single Charger View */}
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Image source={require('../../assets/headerBarIcon.png')} style={{ width: 55, height: 5, marginTop: 12 }} />
          </View>
          <View className="p-4">
            <View className="flex flex-row items-center gap-2">
              <Image 
                source={{ uri: 'https://us.123rf.com/450wm/pekosman/pekosman2308/pekosman230816231/211592972-charging-an-electric-car-in-the-city-close-up.jpg?ver=6' }}
                style={{ width: 90, height: 90, borderRadius: 12 }}
              />
              <View>
                <Text className="text-lg font-bold text-white">{`${props.selectedChargers[0].name}`}</Text>
                <Text className="text-sm font-normal" style={{color:'#8D8E90'}}>{`${props.selectedChargers[0]?.full_address ?? ''}`}</Text>
              </View>
            </View>
            <View style={styles.line} className="my-4" />
            <View className="flex flex-row items-center justify-between">
              <View>
                {props.selectedChargers[0].isOccupied === 0 ? (
                  <Pressable 
                    onPress={() => props.handleViewCharger(props.selectedChargers[0], 0)}
                  >
                    <Text className="px-5 py-2 text-xs font-bold text-white rounded-full" style={{ textAlign: 'center', backgroundColor: '#51B868' }}>
                      Available
                    </Text>
                  </Pressable>
                ) : (
                  <Text className="px-5 py-2 text-xs font-bold text-white rounded-full" style={{ textAlign: 'center', backgroundColor: '#F75555' }}>
                    Not Available
                  </Text>
                )}
              </View>
              <View className="flex flex-row items-center gap-2">
                <FontAwesome6 name="location-dot" size={24} color="#8D8E90" />
                <Text style={{ color: '#8D8E90' }}>
                  {props.myTimeAndDistance[0]?.myDistance !== undefined
                    ? `${props.myTimeAndDistance[0].myDistance} mi`
                    : <LoadingPlaceholder />}
                </Text>
                <FontAwesome5 name="car" size={24} color="#8D8E90" />
                <Text style={{ color: '#8D8E90' }}>
                  {props.myTimeAndDistance[0]?.myTime !== undefined
                    ? `${props.myTimeAndDistance[0].myTime}`
                    : <LoadingPlaceholder />}
                </Text>
              </View>
            </View>
            <View style={styles.line} className="my-4" />
            <View className="flex flex-row items-center gap-4 py-2">
              <Image source={require("../../assets/chargerType.png")} style={{ height: 40, width: 40 }} />
              <View>
                <Text className="text-xs font-normal text-white">
                  {`${props.selectedChargers[0]?.manufacturer_name}-${props.selectedChargers[0]?.charger_type} ${props.selectedChargers[0]?.plug_type ?? ''} Charger`}
                </Text>
                <Text className="text-lg font-semibold text-white">
                  {`$${currentPrice}/kWh ${todayPeak && todayPeak.start !== todayPeak.end
                      ? ` (${priceLabel})`
                      : ''
                  }`}
                </Text>
                { todayPeak &&
                  todayPeak.start !== todayPeak.end &&
                  nextTransition !== '' && (
                    <Text  style={{fontWeight: 'bold', color: '#39BEB7'}}>{nextTransition}</Text>
                )}
              </View>
            </View>
            <View className="flex flex-row justify-between mt-2">
              <DirectionsButton
                selectedCharger={props.selectedChargers[0]}
                buttonStyle={styles.btn1}
                textStyle={{ fontSize: 14, fontWeight: '500', color: '#fff' }}
                webClassName="font-medium text-white"
              />
              <Pressable 
                style={styles.btn2} 
                onPress={() => props.handleViewCharger(props.selectedChargers[0], 0)}
              >
                <Text className="font-medium text-white">View Charger</Text>
              </Pressable>
            </View>
          </View>
        </View>
    )

}

export default ChargerSelectSinglePopup;

const styles = StyleSheet.create({
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  btn1: {
    borderColor: '#8D8E90',
    borderWidth: 2,
    paddingHorizontal:24,
    paddingVertical: 12,
    borderRadius: 30,
    backgroundColor: 'transparent'
  },
  btn2: {
    backgroundColor: '#39BEB7', 
    borderRadius: 30, 
    paddingHorizontal:8, 
    paddingVertical: 12
  },
  textColor: {
    color: '#E8E8E9'
  }
});