  export interface UserAttributes {
    uuid: string;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    role: string;
    createdAt: Date;
    updatedAt: Date;
    address: string;
    phone_number: string;
    profilePicture: Uint8Array;
    account_status: string;
    user_api_key: string;
    user_oauth_secret: string;
    primary_use: string;
    logintype: boolean;
    service: string;
  }
  

  export const createUser = (overrides?: Partial<UserAttributes>): UserAttributes => ({
    uuid: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    address: '',
    phone_number: '',
    profilePicture: new Uint8Array(), // Instantiate with an empty Uint8 array
    account_status: '',
    user_api_key: '',
    user_oauth_secret: '',
    logintype: false,
    primary_use: '',
    service: '',
    ...overrides,
  });

  
export interface LoginRegister {
  uuid: string;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  role: number;
  IpAddress: string;
  address?: string;
  phone_number?: string;
  profilePicture?: string;
  account_status?: string;
  user_api_key?: string;
  user_oauth_secret?: string;
  logintype: boolean;
}

  export const createLoginRegister = (overrides?: Partial<LoginRegister>):LoginRegister => ({
    uuid: '',
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    email: '',
    IpAddress: '',
    role: 0,
    address: '',
    phone_number: '',
    profilePicture: '',
    account_status: '',
    user_api_key: '',
    logintype: false,
    user_oauth_secret: '',
    ...overrides
  });