import { chargerActions } from "../slices/charger-slice";
import { PrepareApiProcedure } from "../../components/common/utils/prepare-api-procedure";
import { ApiRequest, fetchDataFromAPI, fetchDataFromAPITyped, isSuccessful } from "../../services/api-requests";
import { ChargerAttributes } from "../../models/charger-model";
import { notificationService } from "../../services/notification-service";
import { getCurrentUtcSeconds, getPastUtcSeconds } from "../../components/common/utils/date-time-conversions";
import * as Location from 'expo-location';
import { AppDispatch, RootState } from "..";

export const registerNewCharger = async (payload: any) => {
    // Prepare API query and send request
    const apiQuery: ApiRequest = {
    route: 'charger',
    request: {
        method: 'POST',
        entity: 'charger',
        body: payload
    }
    }
    return await fetchDataFromAPITyped<ChargerAttributes>(apiQuery);
}

export const getAllChargers = () => {
    return async (dispatch: AppDispatch) => {
       console.log("getAllChargers");
        try {
            let apiQuery = PrepareApiProcedure('charger/getAll', 'GET', 'charger', '');
            /* Now the apiQuery is an object */
            //console.log("querying", apiQuery);
            const chargerList = await fetchDataFromAPI(apiQuery);
            if(chargerList.fatal === true){
                notificationService.sendNotification('error', `Unable to fetch existing systems\n ${JSON.stringify(chargerList)}`);
            }

            console.log('chargerList:', chargerList)
            dispatch(chargerActions.getAllChargers(
                {
                    chargers: chargerList
                }
            ));

        } catch (e) {
            console.error(e);
        }
    }
}

// export const getAllChargers = async (): Promise<ChargerAttributes[]> => {
//     console.log("getAllChargers");
//     let apiQuery = PrepareApiProcedure('charger/getAll', 'GET', 'charger', '');
//     let chargerList: ChargerAttributes[] = await fetchDataFromAPI(apiQuery);
//     return chargerList;
// }

export const getChargerHistory = (charger:ChargerAttributes) => {
    return async (dispatch: (arg0: {payload: any; type: string; }) => void) => {
        try {
            //create the timestamp
            const apiQuery = PrepareApiProcedure(`charger/${charger.uuid}/${getPastUtcSeconds(30 * 24 * 60 * 60)}/${getCurrentUtcSeconds()}`, 'GET', 'chargerHistory', '');
            let chargerHistoryData = await fetchDataFromAPI(apiQuery);

            dispatch(chargerActions.getChargerHistory(
                {
                    chargerHistory: chargerHistoryData
                }
            ))
        } catch (e) {
            console.error(e);
        }
    }
}

export const getRecentChargerHistory = async (userId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/recentlyUsedByUser/${userId}`, 'GET', 'chargerHistory', '');
        let recentChargerHistory = await fetchDataFromAPI(apiQuery);
        return recentChargerHistory;
    } catch (e) {
        console.error(e);
    }
}

export const selectCharger = (charger:ChargerAttributes) => {
    return async (
        dispatch: AppDispatch,
    ) => {
        dispatch(chargerActions.updateSelectedCharger({
            selectedCharger: charger
        }))
    }
}

export const updateSelectedCharger = (chargerId: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => RootState
    ) => {
        console.log("updating selected charger", chargerId);
        const state = getState();
        const currentCharger = state.chargers.selectedCharger

        if(chargerId === 'failed'){
            const updatedCharger = {
                ...currentCharger,
                uuid: chargerId,
            };
            
            dispatch(chargerActions.updateSelectedCharger({
                selectedCharger: updatedCharger
            }))
            return;
        }

        state.chargers.chargers
        try {
            const apiQuery = PrepareApiProcedure(`charger/getSingleCharger`, 'POST', 'charger', {chargerId: chargerId});
            let updatedCharger = await fetchDataFromAPI(apiQuery);
            if(updatedCharger.fatal === true){
                notificationService.sendNotification('error', `Unable to fetch selected charger\n ${JSON.stringify(updatedCharger)}`);
            }
            //console.log("updated charger object is:", updatedCharger[0]);

            // Get current chargers list from state
            const chargers = state.chargers.chargers;

            // Replace the charger that matches the chargerId with the updatedCharger
            const updatedChargersList = chargers.map((charger: any) =>
                charger.uuid === chargerId ? updatedCharger[0] : charger
            );

            // Dispatch the updated list to the store
            dispatch(chargerActions.getAllChargers({
                chargers: updatedChargersList
            }));

            dispatch(chargerActions.updateSelectedCharger({
                selectedCharger: updatedCharger[0]
            }))
        } catch (e) {
            console.error("Error updating selected charger store:", e);
        }
    }
}

export const deleteUnit = async (unitId: object) => {

}

export const getMyDistanceFromCharger = (myLocation: Location.LocationObjectCoords, charger: ChargerAttributes): number => {
    const R = 6371000; // Radius of the Earth in meters
    const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

    const dLat = toRadians(charger.lat - myLocation.latitude);
    const dLng = toRadians(charger.lng - myLocation.longitude);

    const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(myLocation.latitude)) * Math.cos(toRadians(charger.lat)) *
    Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const meters = R * c; // Distance in meters
    if (isNaN(meters)) {
        return 0;
    }
    const miles = meters * 0.000621371
    return Number(miles.toFixed(1));
}

export const getMyDrivingTime = async (myLocation: Location.LocationObjectCoords, chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(
            `charger/getMyDrivingTimeFromCharger`, 
            'POST', 
            'charger', 
            {
                lat: myLocation.latitude,
                lng: myLocation.longitude,
                chargerId: chargerId
            });
        //console.log("Querying", apiQuery);
        const myDrivingTime = await fetchDataFromAPI(apiQuery);
        //console.log("my driving time:", myDrivingTime);
        return myDrivingTime.drivingTime;
    } catch (error) {
        console.log("error getting my driving time")
    }
}


export const getResidentials = async (latitude: string, longitude: string) => {
       console.log("getResidentials");
        try {
            let apiQuery = PrepareApiProcedure(`nrel/rates/${latitude}/${longitude}`, 'GET', 'rates', '');
            //console.log("querying", apiQuery);
            try{
                const nrelData = await fetchDataFromAPI(apiQuery);
                
                //console.log('nrelResidentialData:', nrelData)
                return nrelData;
            } catch (err) {
                console.error('Error in fetchDataFromAPI:', err);
                throw err;
            }

        } catch (e) {
            console.error(e);
        }
}

export const updateCharger = async(form: any, uuid: string) => {
        try{
            const apiQuery: ApiRequest = {
                route: `charger/update/${uuid}`,
                request: {
                    method: 'PUT',
                    entity: 'charger',
                    body: form
                }
            }
            const registerResp = await fetchDataFromAPITyped(apiQuery);

            if (!registerResp || !isSuccessful(registerResp.status)) {
                notificationService.sendNotification("error", `Failed to update this charger`);
            } else {
                notificationService.sendNotification("success", 'Successfully updated your charger!');
            }
        } catch (e) {
            console.error("Error while registering charger", e);
            notificationService.sendNotification("error", `Error while registering charger ${e}`);
        }
}

export const disableCharger = async(uuid: any) => {
    try{
        const apiQuery = PrepareApiProcedure(`charger/disable/${uuid}`, 'GET', 'charger', '');
        const disableCharger = await fetchDataFromAPI(apiQuery);
        console.log('disableCharger:', disableCharger)

        if (!disableCharger || disableCharger === 'jwt malformed') {
            notificationService.sendNotification("error", `Could not disable this charger`);
        } else {
            notificationService.sendNotification("success", disableCharger.message);
        }
    } catch (e) {
        console.error("Error while disabling charger", e)
    }
}


export const enableCharger = async(uuid: any) => {
    try{
        const apiQuery = PrepareApiProcedure(`charger/enable/${uuid}`, 'GET', 'charger', '');
        const enableCharger = await fetchDataFromAPI(apiQuery);
        console.log('enableCharger:', enableCharger)

        if (!enableCharger || enableCharger === 'jwt malformed') {
            notificationService.sendNotification("error", `Could not enable this charger`);
        } else {
            notificationService.sendNotification("success", enableCharger.message);
        }
    } catch (e) {
        console.error("Error while enabling charger", e)
    }
}

//FUNCTION: getMyChargers
// DESCRIPTION: Get my registered chargers for the charging history screen
export const getMyChargers = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const apiQuery = PrepareApiProcedure(
                'charger/getMyChargers',
                'GET',
                'charger',
                ''
            );
            const myChargerData = await fetchDataFromAPI(apiQuery);
            console.log(myChargerData);
            if (Array.isArray(myChargerData)){
                dispatch(chargerActions.getMyChargers(
                    {
                        myChargers: myChargerData
                    }
                ));
            } 
            else if (myChargerData === 'jwt malformed') {
                //notificationService.sendNotification('error', `You are not logged in! Please log in and try again`)
            } else {
                notificationService.sendNotification('error', `Error retrieving chargers ${myChargerData}`)
            }
        } catch (error) {
            console.log("Error getting my chargers:", error);
        }
    }
}

export const chargerQrScan = (chargerSerial: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const apiQuery = PrepareApiProcedure(
                `charger/chargerscan/${chargerSerial}`,
                'GET',
                'charger',
                ''
            );
            const chargerData = await fetchDataFromAPI(apiQuery);
            if (chargerData && chargerData.errStatus === undefined) {
                const uuid = chargerData;
                dispatch(updateSelectedCharger(uuid)); // Update Redux
            } else {
                console.error('Failed to fetch charger');
                dispatch(updateSelectedCharger('failed'));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
}