//Charger Scan Handler manages incoming QR scans
// The Serial Number will be turned into the charger UUID
// The charger will be selected
// And the ChargingStation screen will be loaded

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationProp, RouteProp, useNavigation, useRoute, useIsFocused } from '@react-navigation/native';
import { updateSelectedCharger } from '../redux/actions';
import { chargerQrScan, getMyDistanceFromCharger, getMyDrivingTime } from '../../store/actions/charging-actions';
import { RootState } from '../../store';
import { ChargerAttributes } from '../../models/charger-model';
import * as Location from 'expo-location';
import {
    Platform
  } from "react-native";
import { notificationService } from '../../services/notification-service';
import { RootStackParamList } from '../MeanderStack';
import { getUserSession, getUserSessionObj } from '../../services/user-session-service';
import { UserSessionAttributes } from '../../models/user-session-model';
import { setSession } from '../../store/actions/user-session';
import { AppDispatch } from '../../store';
import { getUserLocation } from './utils/get-user-location';

const ChargerScanHandler = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    let [myLocation, setMyLocation] = useState<Location.LocationObjectCoords | null>(null);
    const isFocused = useIsFocused();

    const route = useRoute<RouteProp<RootStackParamList, 'ChargerScan'>>();
    const { chargerSerial } = route.params;

    const selectedCharger: ChargerAttributes = useSelector((state: RootState) => state.chargers.selectedCharger);
    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);


    useEffect(() => {
        const fetchChargerAndNavigate = async () => {
            if(session){
                //no need to try to fetch if we are not logged in and there is no user session
                try {
                    dispatch(chargerQrScan(chargerSerial));
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchChargerAndNavigate();
    }, [chargerSerial, dispatch, navigation, session]);

    useEffect(() => {

        //log in the user
        const getSessionObj = async () => {
            const sessionObj = await getUserSessionObj();
            dispatch(setSession(sessionObj));
        }

        const requestLocation = async () => {
            const userLocation = await getUserLocation();
            setMyLocation(userLocation);
        };

        getSessionObj();
        requestLocation();
    }, []);

    useEffect(() => {
            const loadMyInfo = async () => {
                if (!isFocused || !session || !myLocation) return;
                //Wait a little bit to allow selectedCharger to update
                //await new Promise((resolve) => setTimeout(resolve, 100));
                if(selectedCharger.uuid === 'failed') {
                    navigation.navigate('ChargingStationNotFound', {
                        chargerSerial: chargerSerial
                    })
                    return;
                }
                if(selectedCharger.uuid !== '' && myLocation && session){
                    const myDistance: string = (getMyDistanceFromCharger(myLocation, selectedCharger.uuid)).toString();
                    const myDrivingTime: string = await getMyDrivingTime(myLocation, selectedCharger.uuid);
                    navigation.navigate('ChargerStation', {
                        myDistanceFromCharger: myDistance[0], 
                        myTimeFromCharger: myDrivingTime[0]
                    });  // Navigate to the ChargerStation screen
                }
            }
        
        loadMyInfo();
        console.log("Trying to navigate to charger:", selectedCharger);
    }, [selectedCharger, myLocation, session, isFocused])

    return null; // No UI is rendered by this component
};

export default ChargerScanHandler;