import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import CreateAccountForm from './CreateAccountForm';

const { width, height } = Dimensions.get('window');

type Props = NativeStackScreenProps<RootStackParamList, 'CreateAccount'>

const CreateAccount: React.FC<Props> = ({navigation, route}) => {
    return (
        <View>
            <CreateAccountForm 
                formEmail={route?.params?.formEmail || ''} 
                navigation={navigation}    
            />
        </View>
    )
};

export default CreateAccount;

