export interface UserSessionAttributes {
  message: string;
  access_token: string;
  id: string; //user id
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  IpAddress: string | null;
  signature: string;
  scope: string;
  instance_url: string;
  token_type: string;
  issued_at: string;
  expires_at: string;
  primary_use: string;
  role: string;
  password: string;
  logintype: boolean;
  service: string;
}

export const createUserSession = (overrides?: Partial<UserSessionAttributes>): UserSessionAttributes => ({
  message: '',
  access_token: '',
  id: '', //user id
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  IpAddress: '1.1.1.1',
  signature: '',
  scope: '',
  instance_url: '',
  token_type: '',
  issued_at: '',
  expires_at: '',
  primary_use: '',
  role: '',
  password: '',
  logintype: true,
  service: '',
  ...overrides,
});

