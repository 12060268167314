// Generate random alphanumeric characters

export const generatePassword = (len: number): string => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%';
    let randomAlphanumeric = '';
    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomAlphanumeric += characters[randomIndex];
    }
    return randomAlphanumeric;
}