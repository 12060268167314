import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Text,
  View,
} from "react-native";
import { getRateTransitions } from "./common/utils/get-price-info";
import { ChargerAttributes } from "../models/charger-model";
import { generatePeakObj } from "./common/utils/peak-off-peak-field";

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface RateDisplayProps {
    renderCharger: ChargerAttributes;
}

/**
 * RateDisplay Component
 *
 * This component shows two rows:
 * - One for the Peak rate (only rendered if a peak price is provided and the schedule is valid)
 * - One for the Off-Peak rate.
 *
 * Next to each rate the extra time info is appended.
 */
const RateDisplay: React.FC<RateDisplayProps> = ({ renderCharger }) => {
    const peakData = generatePeakObj(renderCharger.peak_selection);
    const now = new Date();
    const todayName = days[now.getDay()];
    const todaySchedule = peakData[todayName];
    const validPeakSchedule = todaySchedule && todaySchedule.start !== todaySchedule.end;
  
    const { peakTransition, offPeakTransition } = getRateTransitions(renderCharger);
  
    return (
        <View>
            {/* Render the Peak Rate row only if a peak price exists and a valid peak schedule is detected */}
            {renderCharger.price_kwh_peak && validPeakSchedule && (
            <View className="flex flex-row items-center justify-between">
            {/* Left column: Label + Transition text */}
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text className="text-base font-semibold text-white">Peak rate</Text>
                {peakTransition && (
                    <Text
                        style={{
                            color: '#39BEB7',
                            fontSize: 12, // Slightly smaller text
                            marginLeft: 4,
                            fontWeight: 'bold',
                        }}
                    >
                        {`[${peakTransition}]`}
                    </Text>
                )}
            </View>
            {/* Right column: Price */}
            <Text className="text-base font-semibold text-white">
                {`$${renderCharger.price_kwh_peak}/kWh`}
            </Text>
            </View>
        )}

        {/* Render Off-Peak Rate row (always displayed) */}
        <View className="flex flex-row items-center justify-between">
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text className="text-base font-semibold text-white">Off-Peak rate</Text>
            {offPeakTransition && (
                <Text
                    style={{
                        color: '#39BEB7',
                        fontSize: 12,
                        marginLeft: 4,
                        fontWeight: 'bold',
                    }}
                >
                    {`[${offPeakTransition}]`}
                </Text>
            )}
            </View>
            <Text className="text-base font-semibold text-white">
                {`$${renderCharger.price_kwh_off_peak}/kWh`}
            </Text>
        </View>
        </View>
    );
  };

  export default RateDisplay;