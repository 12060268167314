import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  Image,
  Pressable,
  StyleSheet,
  Dimensions,
  SafeAreaView,
  Animated
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const { width, height } = Dimensions.get("window");
const ICON_SIZE = { width: 24, height: 24 };
const ACTIVE_TINT = "#40BFB4";
const DEFAULT_TINT = "black";
const FLASH_COLOR = "#39BEB7";

import carIcon from "../images/carNonHightighted.png";
import pumpIcon from "../images/pumpNonHighlighted.png";
import profileIcon from "../images/profileNonHighlighted.png";
import historyIcon from "../../assets/transactionIcon.png";

const icons = { car: carIcon, pump: pumpIcon, profile: profileIcon };

interface ButtonConfig {
  name: string;
  route: string;
  label: string;
  image: any;
  params?: object;
  disabled?: boolean;
}

const Footer = ({ currentRouteName }: { currentRouteName: string }) => {
  const [activeTab, setActiveTab] = useState(currentRouteName);
  const session = useSelector((state: RootState) => state.user.session);
  const navigation = useNavigation();
  const isGuest = session?.role === "guest";
  const primaryUse = session?.primary_use;
  const scaleAnim = useRef(new Animated.Value(1)).current;
  const flashAnim = useRef(new Animated.Value(1)).current;
  const backgroundColorAnim = useRef(new Animated.Value(0)).current;
  const footerScaleAnim = useRef(new Animated.Value(1)).current;
  const isFirstRender = useRef(true);

  useEffect(() => {
    if( isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    Animated.parallel([
      Animated.sequence([
        Animated.timing(backgroundColorAnim, { toValue: 1, duration: 150, useNativeDriver: false }),
        Animated.timing(backgroundColorAnim, { toValue: 0, duration: 150, useNativeDriver: false }),
        Animated.timing(backgroundColorAnim, { toValue: 1, duration: 150, useNativeDriver: false }),
        Animated.timing(backgroundColorAnim, { toValue: 0, duration: 150, useNativeDriver: false }),
      ]),
      Animated.sequence([
        Animated.timing(flashAnim, { toValue: 0.3, duration: 150, useNativeDriver: true }),
        Animated.timing(flashAnim, { toValue: 1, duration: 150, useNativeDriver: true })
      ]),
      Animated.sequence([
        Animated.timing(footerScaleAnim, { toValue: 1.2, duration: 150, useNativeDriver: true }),
        Animated.timing(footerScaleAnim, { toValue: 1, duration: 150, useNativeDriver: true })
      ])
    ]).start();
  }, [primaryUse]);

  const handlePress = (tab: string, route: string, params?: object) => {
    setActiveTab(route);
    Animated.sequence([
      Animated.timing(scaleAnim, { toValue: 1.2, duration: 300, useNativeDriver: true }),
      Animated.timing(scaleAnim, { toValue: 1, duration: 100, useNativeDriver: true })
    ]).start();
    navigation.navigate(route, params);
  };

  const backgroundColor = backgroundColorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["#FAFAFA", FLASH_COLOR]
  });

  const buttons: ButtonConfig[] = primaryUse === "host"
    ? [
        { name: "pump", route: "MyChargers", label: "My Chargers", disabled: isGuest, image: pumpIcon },
        { name: "profile", route: "MyAccount", label: "My Account", image: profileIcon },
      ]
    : (primaryUse === "driver" || primaryUse === "guest" || primaryUse === "" || !primaryUse)
    ? [
        { name: "history", route: "TransactionHistory", label: "Charging History", image: historyIcon },
        { name: "car", route: "ChargerSelection", params: { tabSelect: "Map" }, label: "Find a Charger", image: carIcon },
        { name: "profile", route: "MyAccount", label: "Menu", image: profileIcon },
      ]
    : [];


    return (
      <SafeAreaView>
        <Animated.View style={[styles.container, { width: width < 430 ? width : 430, opacity: flashAnim, backgroundColor, transform: [{ scale: footerScaleAnim }] }]}> 
        {/* <View style={[styles.container, { width: width < 430 ? width : 430 }]}> */}
          {buttons.map(({ name, route, params, label, disabled, image }) => (
            <Pressable
              key={name}
              onPress={() => !disabled && handlePress(name, route, params)}
              disabled={disabled}
            >
              <Animated.View style={[styles.iconWrapper, { transform: [{ scale: activeTab === name ? scaleAnim : 1 }] }]}>
                <Image
                  source={image}
                  style={[ICON_SIZE, { tintColor: name === "history" ? DEFAULT_TINT : undefined }, activeTab === route && { tintColor: ACTIVE_TINT }]} 
                  resizeMode="contain"
                />
                <Text style={styles.text}>{label}</Text>
              </Animated.View>
            </Pressable>
          ))}
        </Animated.View>
      </SafeAreaView>
    );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FAFAFA",
    padding: 24,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: height * 0.07,
  },
  iconWrapper: {
    alignItems: "center",
  },
  text: {
    color: "gray",
    fontSize: 12,
  },
});
