import {useEffect, useRef, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    ScrollView,
    Pressable,
  } from "react-native";
const { width, height } = Dimensions.get("window");
import { Entypo, FontAwesome6 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import { RouteProp } from "@react-navigation/native";
import { getDateString, getTimeString, secondsToHHMMSS } from "../components/common/utils/date-time-conversions";
import { getChargerHistoryByOrder } from "../services/charger-history-service";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getPaymentDetails } from "../services/payments-service";
import CreateAccountForm from "./CreateAccountForm";
import { UserSessionAttributes } from "../models/user-session-model";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import SignInOptions from "./SignInOptions";
import { isSuccessful } from "../services/api-requests";
import { ChargerHistoryAttributes } from "../models/charger-history-model";

type ChargeSummaryNavigationProp = NativeStackNavigationProp<RootStackParamList, 'ChargeSummary'>;
type ChargeSummaryRouteProp = RouteProp<RootStackParamList, 'ChargeSummary'>;

type Props = {
    navigation: ChargeSummaryNavigationProp,
    route: ChargeSummaryRouteProp,
}

export const ChargeSummary = ({navigation, route}) => {
    // const selectedCharger: ChargerAttributes = useSelector(
    //     (state: RootState) => state.chargers.selectedCharger
    // ) as ChargerAttributes
    //pass the charger in by route, 
    //  as this charge summary screen will also be generated from transaction history
    //const charger:ChargerAttributes = route?.params.charger;
    const [chargerHistory, setChargerHistory] = useState<ChargerHistoryAttributes | null>(null);
    const [userEmail, setUserEmail] = useState<string | null>('');
    const [pt, setPt] = useState<number>(0);
    const [opt, setOpt] = useState<number>(0);
    const [lastFourCard, setLastFourCard] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState('');
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const {charger, startTime, endTime, orderId} = route?.params;
    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
    //const startTime = getDateFromUtcSeconds(1716806822);
    //const endTime = getDateFromUtcSeconds(1716829802);

    // console.log("got charger:", charger);
    // console.log("start time: ", getDateInUtcSeconds(startTime));
    // console.log("endTime:", getDateInUtcSeconds(endTime));
    
    //grab the charger history
    // 'api/charger/chargerHistoryByTime'
    useEffect (() => {
        const getHistory = async () => {
            const history = await getChargerHistoryByOrder(orderId);
            if (history?.message === "Did not find any activity for this time period") {
                //set everything on the screen to 0, or a message about invalid charging period
            } else {
                setChargerHistory(history.payload);
            }
        }

        const getUserEmail = async () => {
            const email = await AsyncStorage.getItem('email');
            setUserEmail(email);
        }

        getHistory();
        getUserEmail();
    }, [])

    useEffect(() => {
        const cardLastFour = async () => {
            const paymentDetailsResponse = await getPaymentDetails(orderId);
            console.log("Got paymentDetailsResponse:", paymentDetailsResponse);
            if (isSuccessful(paymentDetailsResponse.status)) {
                setLastFourCard(paymentDetailsResponse.payload?.lastFour ?? 'unknown');
            } else {
                console.error("Unable to retrieve payment details.")
            }
        }

        cardLastFour();
    }, [orderId]);
    
    //once we have all the charger history data, parse it for the output
    useEffect (() => {
        //determine peak/off-peak durations and final cost here
        // we have all the data from the charger at this point
        if (chargerHistory){
            setPt(chargerHistory?.peak_charge_time ?? 0);
            setOpt(chargerHistory?.off_peak_charge_time ?? 0);
        }
    }, [chargerHistory]);

    const handleOptionSelect = (value) => {
        setSelectedOption(value === selectedOption ? '' : value); // Toggle selection
    };
    
    const handleCreateAccount = ():void => {
        setShowCreateAccount(!showCreateAccount);
    }

    const refRBSheet = useRef();
    const refCreateAccount = useRef<View>(null);
    const refScrollView = useRef<ScrollView | null>(null);
    const OpenBottomSheet = () => {
        if (refRBSheet.current) {
            refRBSheet.current.open();
        }
    }

    useEffect(() => {
        if (showCreateAccount && refCreateAccount.current && refScrollView.current) {
          try {
            refCreateAccount.current.measure((x, y, width, height, pageX, pageY) => {
              if (pageY != null) {
                // Attempt to scroll to the calculated position
                refScrollView.current?.scrollTo({ y: pageY, animated: true });
              }
            });
          } catch (error) {
            console.error("Error during scrolling:", error);
            fallbackScrollToEnd();
          }
        }
    }, [showCreateAccount]);
      
    // Fallback to scroll to the end of the ScrollView
    const fallbackScrollToEnd = () => {
        try {
            refScrollView.current?.scrollToEnd({ animated: true });
        } catch (error) {
            console.error("Error in fallback scrolling:", error);
        }
    };

    const handleSignIn = ():void => {
        // use the signin popup
        // TODO: make sure to save this screen and come back to it!
        OpenBottomSheet();
    }
    
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView ref={refScrollView}>
                    <View className="p-4">
                            <Text className="text-base font-semibold text-white">Your Charge Summary</Text>
                            <View className="flex flex-row items-center gap-3 pb-3 mt-3 ml-0 rounded-2xl" style={styles.BgColor1}>
                                <Image
                                    source={require("../../assets/RegisterBgImage.png")}
                                    style={{ width: 100, height: 100, borderRadius: 12 }}
                                    alt="Station Image"
                                />
                                <View style={styles.address}>
                                    <Text className="text-lg font-bold text-white">
                                        {`${charger.name}`}
                                    </Text>
                                    <Text className="text-sm font-normal" style={styles.textColor}>
                                        {`${charger.full_address}`}
                                    </Text>
                                </View>
                            </View>

                            <View className="mt-4">
                                <Text className="mb-3 text-base font-semibold text-white">Charger</Text>
                                <View className="flex flex-row items-center gap-4">
                                    <Image
                                        source={require("../../assets/chargerType.png")}
                                        style={{ width: 32, height: 32 }}
                                        alt="Station Image"
                                    />
                                    <View>
                                        <Text style={styles.textColor2} className="text-xs font-normal">
                                            {`${charger.charger_model} - ${charger.charger_type} J1772 Charger`}
                                        </Text>
                                        <View className="flex flex-row items-center">
                                            <Text className="text-lg font-semibold text-white">
                                                {`${charger.charging_capacity} kW`}
                                            </Text>
                                            <Entypo name="dot-single" size={24} color="#8D8E90" />
                                            <Text style={styles.textColor}>
                                                power
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-3">
                                    <Text className="text-base font-semibold text-white">Peak rate</Text>
                                    <Text className="text-base font-semibold text-white">
                                        <FontAwesome name="dollar" size={15} color="white" className="mr-1"/>
                                        {chargerHistory?.price_basis_peak !== undefined 
                                            ? `${chargerHistory?.price_basis_peak.toFixed(2)}` 
                                            : "Unknown"}
                                    </Text>
                                    <Text className="text-base font-semibold text-white">Off-Peak rate</Text>
                                    <Text className="text-base font-semibold text-white">
                                        <FontAwesome name="dollar" size={15} color="white" className="mr-1"/>
                                        {chargerHistory?.price_basis_off_peak !== undefined 
                                            ? `${chargerHistory?.price_basis_off_peak.toFixed(2)}` 
                                            : "Unknown"}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.line}/>

                            {chargerHistory && <View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Booking Date</Text>
                                    <Text className="text-sm font-normal text-white">{`${getDateString(startTime)}`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Time of Arrival</Text>
                                    <Text className="text-sm font-normal text-white">{`${getTimeString(startTime)}`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Total Charging Duration</Text>
                                    <Text className="text-sm font-normal text-white">{`${secondsToHHMMSS(pt+opt)}`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Peak Rate kWh Usage</Text>
                                    <Text className="text-sm font-normal text-white">{`${(chargerHistory?.peak_kwh ?? 0).toFixed(2)} kWh`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Off-Peak Rate kWh Usage</Text>
                                    <Text className="text-sm font-normal text-white">{`${(chargerHistory?.off_peak_kwh ?? 0).toFixed(2)} kWh`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Charging Cost</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${(chargerHistory.total_charge_price ?? 0 - (chargerHistory.tax ?? 0)).toFixed(2)}`}
                                    </Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Tax</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${chargerHistory.tax?.toFixed(2) ?? 0}`}
                                    </Text>
                                </View>
                                {/* Total cost */}
                                <View className="flex flex-row items-center justify-between p-4 mt-2 mb-2 rounded-full" style={styles.BgColor1}>
                                    <Text style={styles.textColor}>Total cost</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${chargerHistory.total_charge_price?.toFixed(2) ?? 0}`}
                                    </Text>
                                </View>
                            </View>}

                            <View className="mt-1">
                                <Text className="text-base font-semibold text-white">Payment Method</Text>
                                <View className="flex flex-row items-center gap-4 mt-1 mb-1">
                                    <Image
                                        source={require("../../assets/wallet.png")}
                                        style={{ width: 32, height: 32 }}
                                        alt="Station Image"
                                    />
                                    <Text className="text-lg font-semibold text-white">{`Card ending in ${lastFourCard}`}</Text>
                                </View>
                            </View>

                            <View style={styles.line}/>
                            {(!userEmail || session?.role === 'guest') && 
                                <View>
                                    <Pressable onPress={() => {
                                        handleCreateAccount();
                                        handleOptionSelect('checked');
                                    }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center'}} className="mt-2 ml-2">
                                        <View style={styles.checkBox}>
                                        { 
                                            selectedOption === 'checked' && 
                                            <View style={{ width: 17, height: 17, }}> 
                                            <FontAwesome6 name="check" size={17} color="black" />
                                            </View>
                                        }
                                        </View>
                                        <Text 
                                            className='pl-1 text-bold'
                                            style={[styles.signInColor, styles.signInText]} 
                                        >
                                            Create an account
                                        </Text>
                                        <Text style={styles.textColor2} className="text-xs font-medium">{ ' to check out faster next time or ' }</Text> 
                                        <Text style={[styles.signInColor, styles.signInText]} className='pl-1 text-bold' onPress={handleSignIn}>
                                            Sign In
                                        </Text>
                                    </View>
                                    </Pressable>
                                </View>
                            }
                            {showCreateAccount &&
                                <View ref={refCreateAccount}>
                                    <CreateAccountForm
                                        formEmail=''
                                        handleCreateAccount={handleCreateAccount}
                                        showAltLogins={true}
                                        navigation={navigation}
                                    />
                                </View>
                            }
                    </View>
                </ScrollView>
                <SignInOptions
                    navigation={navigation}
                    refRBSheet={refRBSheet}
                    destination={'ChargeSummary'}
                />
            </View>
        </SafeAreaView>
    )
}

export default ChargeSummary;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
      },
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    BgColor3: {
        backgroundColor: '#E8E8E9'
    },
    BgColor4: {
        backgroundColor: '#4F7F8B'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'solid',
        marginVertical: 20,
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor2: {
        color: '#E8E8E9'
    },
    textColor3: {
        color: '#D1D2D3'
    },
    textColorTeal: {
        color: '#39BEB7',
    },
    signInColor: {
        color: '#39BEB7',
    },
        signInText: {
        fontWeight: 'bold',
        textDecorationLine: 'underline',
    },
    address: {
        width: width <= 430 ? width * 0.5 : 250,
    }
})

