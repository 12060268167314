import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { loginUser, validateUserToken } from '../store/actions/user-actions';
import { notificationService } from './notification-service';
import { LinearTransition } from 'react-native-reanimated';
import { generatePassword } from '../components/common/utils/password-gen';

export const getUserSessionObj = async (): Promise<UserSessionAttributes> => {

    // const token =  await AsyncStorage.getItem("token");
    // const IpAddress = await AsyncStorage.getItem("IpAddress");
    // const email = await AsyncStorage.getItem("email");
    // const UserId = await AsyncStorage.getItem("userId");
    // const role = await AsyncStorage.getItem("role");
    // Retrieve the full token object from AsyncStorage
    const tokenData = await AsyncStorage.getItem('userSession');

    let createSession;
    let sessionObj;
    if(tokenData){
       // Parse the JSON stored in token
       const parsedToken: Partial<UserSessionAttributes> = JSON.parse(tokenData);
       // Check if all necessary fields exist in the parsed token
       const requiredFields = ['access_token', 'IpAddress', 'email', 'id', 'role'];
       const isTokenValid = requiredFields.every(field => parsedToken[field as keyof UserSessionAttributes] !== undefined);
        sessionObj = createUserSession({
            access_token: parsedToken.access_token, 
            IpAddress: parsedToken.IpAddress, 
            email: parsedToken.email, 
            username: parsedToken.username,
            id: parsedToken.id, 
            role: parsedToken.role,
            primary_use: parsedToken.primary_use,
        })
        //store.dispatch(setSession(createSession))
    } else {
        //not logged in, set the guest login
        let ip = await getIpAddressFunc();
        if(!ip) {ip = "1.1.1.1"}
        createSession = createUserSession({
            email: "guest",
            username: "guest",
            password: generatePassword(16),
            IpAddress: ip,
            logintype: true,
            access_token: "",
            role: 'guest',
            primary_use: 'guest',
        });

        //get the token
        //store.dispatch(userActions.loginUser({userLogin: createSession}));
        //store.dispatch(setSession(sessionObj))
        sessionObj = await loginUser(createSession);
    }
    
    return sessionObj;
}

/**
 * Stores the user session object in AsyncStorage
 * @param userSession - Object containing user session data based on UserSessionAttributes
 */
export const storeUserSession = async (userSession: UserSessionAttributes): Promise<void> => {
    try {
      // Store the user session object as a JSON string in AsyncStorage
      await AsyncStorage.setItem('userSession', JSON.stringify(userSession));
      //notificationService.sendNotification('success', 'User session stored!')
      console.log('User session stored successfully');
    } catch (error) {
      console.error('Error storing user session: ', error);
      notificationService.sendNotification('error', `Unable to store user session! ${error}`)
    }
  };

  /**
 * Retrieves the user session object from AsyncStorage and recreates it as a UserSessionAttributes object
 * @returns UserSessionAttributes | null - Returns the user session or null if not found
 */
export const getUserSession = async (): Promise<UserSessionAttributes | null> => {
    try {
      const sessionData = await AsyncStorage.getItem('userSession');
      if (sessionData !== null && sessionData !== 'undefined') {
        // Parse the retrieved data and recreate the UserSessionAttributes object
        let parsedSession: UserSessionAttributes = JSON.parse(sessionData);
        if(parsedSession.message === "Invalid token" || parsedSession.message === 'Session not found'){
            //invalid session, we need to create a new one
            await AsyncStorage.clear();
            const ip = await getIpAddressFunc();
            const createSession = createUserSession({
                email: "guest",
                username: "guest",
                password: generatePassword(16),
                IpAddress: ip,
                logintype: true,
                access_token: "",
            })
            parsedSession = await loginUser(createSession);
        }
        return createUserSession(parsedSession); // Use createUserSession to handle missing fields
      } else {
        //not logged in, set the guest login
        var ip = await getIpAddressFunc();
        if(!ip) {ip = "1.1.1.1"}
        const createSession = createUserSession({
            email: "guest",
            username: "guest",
            password: generatePassword(16),
            IpAddress: ip,
            logintype: true,
            access_token: "",
        });
        const sessionObj = await loginUser(createSession);
        const newSessionData = await validateUserToken();
        return createUserSession(newSessionData);
      }
    } catch (error) {
      console.error('Error retrieving user session: ', error);
      return null;
    }
  };