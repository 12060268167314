import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Modal, SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { RootState } from '../store';
import { getAllChargers, updateSelectedCharger } from '../store/actions/charging-actions';
import { Tooltip } from 'react-native-elements';
import { PrepareApiProcedure } from '../components/common/utils/prepare-api-procedure';
import { fetchDataFromAPI } from '../services/api-requests';
import DatePicker from "../components/DateTimePicker";
import { notificationService } from "../services/notification-service";
import { ChargerHistoryAttributes } from '../models/charger-history-model';
import ChargeHistoryCard from './ChargeHistoryCard';
import { getChargingHistoryByUser, getMyChargerHistory } from '../services/charger-history-service';
import { AppDispatch } from '../store';
import { getSingleChargerData } from '../services/charger-service';
import DateFilterModal from '../components/modals/DateFilterModal';
import { getCurrentDate, getOneMonthBackDate } from '../components/common/utils/date-time-conversions';

const { width, height } = Dimensions.get('window');

const TransactionHistory = ({ navigation }) => {
    const chargers = useSelector((state: RootState) => state.chargers.chargers);
    const dispatch: AppDispatch = useDispatch();
  
    const [chargingHistory, setChargingHistory] = useState<ChargerHistoryAttributes[]>([]);
    const [filteredCharger, setFilteredCharger] = useState<ChargerHistoryAttributes[] | undefined>();
    const [query, setQuery] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [customDates, setCustomDates] = useState<{ start: string | null, end: string | null }>({
            start: getOneMonthBackDate(),
            end: getCurrentDate(),
        });
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const scrollViewRef = useRef<ScrollView>(null);


    const handleSetSelectedOption = (option: any) => {
        setSelectedOption(option);
    }
  
    // Fetch charging history and associated chargers
    useEffect(() => {
      const fetchChargingHistory = async () => {
        const history = await getChargingHistoryByUser();

        const sortedHistory =
            history.sort((a: ChargerHistoryAttributes, b: ChargerHistoryAttributes) => {
                const dateA = a.charging_start_time ? new Date(a.charging_start_time).getTime() : 0;
                const dateB = b.charging_start_time ? new Date(b.charging_start_time).getTime() : 0;
            
                return dateB - dateA;
            });
        
        // Remove duplicates based on `orderId`
        const uniqueHistory = sortedHistory.filter(
            (item: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t.orderId === item.orderId)
        );

        setChargingHistory(uniqueHistory);
      };
      fetchChargingHistory();
    }, []);
  
    // Filter chargers by date range
    const filterByDateRange = (
        data: ChargerHistoryAttributes[],
        startDate: string,
        endDate: string
      ) => {
        // Convert startDate and endDate to Date objects for comparison
        const start = new Date(startDate).getTime();
        // Advance endDate by one day and convert to timestamp
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1); // Add one day
        const end = adjustedEndDate.getTime();
      
        return data.filter(({ charging_start_time }) => {
          const chargingStartTime = charging_start_time
            ? new Date(charging_start_time).getTime()
            : null;
      
          // Ensure the chargingStartTime exists and is within the date range
          return chargingStartTime && chargingStartTime >= start && chargingStartTime < end;
        });
      };
  
    const handleFilterDate = (setDates: any) => {
    setCustomDates({ start: setDates.start, end: setDates.end });
    
    if (setDates.start && setDates.end) {
        setFilteredCharger(filterByDateRange(chargingHistory, setDates.start, setDates.end));
        notificationService.sendNotification('success', 'Date filter applied.');
    }
    setModalVisible(false);
    };

    const handleCancel = () => {
        setModalVisible(!modalVisible);
    };
  
    const resetFilter = () => {
      setFilteredCharger(undefined);
      setModalVisible(false);
      notificationService.sendNotification('success', 'Date filter removed.');
    };
  
    // Filter dropdown and search
    const dropdownData = useMemo(() => {
      const data = filteredCharger || chargingHistory;
      return data
        .filter(
            (item, index, self) => index === self.findIndex((t) => t.orderId === item.orderId)
        )
        .map((item) => ({
            id: item.orderId,
            title: `${item.chargerId}: ${item.total_charge_time}`,
            charger: chargers.find((c) => c.uuid === item.chargerId),
        }));
    }, [filteredCharger, chargingHistory, chargers]);
  
    const filteredData = useMemo(
      () =>
        dropdownData.filter(({ charger }) =>
          [charger?.uuid, charger?.name, charger?.city]
            .filter(Boolean)
            .some((field) => typeof field === 'string' && field.toLowerCase().startsWith(query.toLowerCase()))
        ),
      [dropdownData, query]
    );

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
      
        // Remove duplicates in `filteredData` by `orderId` before slicing
        const uniqueFilteredData = filteredData.filter(
            (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );

        // Apply pagination to filteredData
        return uniqueFilteredData.slice(startIndex, endIndex);
    }, [filteredData, currentPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        scrollViewRef?.current?.scrollTo({ x: 0, y: 0, animated: true }); // Scroll to the top
    };
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const getPaginationNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }
        return pages;
    };
  
    // Navigate to receipt
    const showReceipt = async (history: ChargerHistoryAttributes, index: string) => {
      const chargerObj = await getSingleChargerData(history.chargerId);
      navigation.navigate('ChargeSummary', {
        charger: chargerObj,
        startTime: history.charging_start_time
          ? new Date(history.charging_start_time)
          : null,
        endTime: history.charging_end_time ? new Date(history.charging_end_time) : null,
        orderId: history.orderId,
      });
    };
  
    return (
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.container}>
            <View style={styles.searchContainer}>
              <Ionicons name="search" size={22} color="white" style={styles.searchIcon} />
              <TextInput
                placeholder="Search charger"
                style={styles.input}
                placeholderTextColor="#a0aec0"
                value={query}
                onChangeText={setQuery}
              />
              {/* Filter graphic button */}
                <Pressable onPress={() => setModalVisible(true)}>
                    <Image
                    source={require('../../assets/searchFiltering.png')} // Ensure this path is correct
                    style={styles.filterIcon}
                    />
                </Pressable>
              <Pressable onPress={() => setQuery((q) => q.slice(0, -1))}>
                <Ionicons name="backspace-outline" size={38} color="white" style={styles.backSpaceIcon} />
              </Pressable>
            </View>
            <ScrollView ref={scrollViewRef}>
                {paginatedData.length === 0 &&
                  <View>
                    <Text className='text-2xl font-bold text-center text-white'>
                      {`No charges here yet!\nCome back after your first charge.`}
                    </Text>
                  </View>
                }
                {paginatedData
                .filter(
                    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
                )
                .map((item, index) => {
                    const chargerHistory = chargingHistory.find((ch) => ch.orderId === item.id);
                    const charger = chargers.find((c) => c.uuid === chargerHistory?.chargerId);

                    if (!charger || !chargerHistory) return null;

                    return (
                        <Pressable
                            onPress={() => showReceipt(chargerHistory, item.id)}
                            key={item.id}
                        >
                        <ChargeHistoryCard
                            index={index}
                            chargerHistory={chargerHistory}
                            charger={charger}
                            isPressable
                        />
                        </Pressable>
                    );
                })}
            </ScrollView>
            <View style={styles.paginationContainer}>
                <Pressable
                    onPress={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <Text style={currentPage === 1 ? styles.disabledPage : styles.navigationButton}>
                    Previous
                    </Text>
                </Pressable>

                {getPaginationNumbers().map((page) => (
                    <Pressable key={page} onPress={() => handlePageChange(page)}>
                    <Text
                        style={currentPage === page ? styles.activePage : styles.inactivePage}
                    >
                        {page}
                    </Text>
                    </Pressable>
                ))}

                <Pressable
                    onPress={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <Text style={currentPage === totalPages ? styles.disabledPage : styles.navigationButton}>
                    Next
                    </Text>
                </Pressable>
            </View>
        </View>
        <Modal
          animationType="fade"
          transparent
          visible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
            <DateFilterModal
                selectedOption={selectedOption}
                setSelectedOption={handleSetSelectedOption}
                filterDate={handleFilterDate}
                handleCancel={handleCancel}
                customDates={customDates}
            />
        </Modal>
      </SafeAreaView>
    );
  };
  
  const styles = StyleSheet.create({
    safeArea: {
      flex: 1,
      backgroundColor: '#0B1E1D',
    },
    container: {
      flex: 1,
      backgroundColor: '#1A1D21',
    },
    searchContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
    },
    searchIcon: {
      marginRight: 10,
    },
    input: {
      flex: 1,
      padding: 10,
      backgroundColor: '#fff',
      borderRadius: 20,
    },
    filterIcon: {
        height: 19,
        width: 19,
        position: 'absolute',
        right: 10,
        top: -9
    },
    backSpaceIcon: {
      marginLeft: 10,
    },
    modal: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#292D33',
      padding: 20,
    },
    modalTitle: {
      fontSize: 18,
      color: '#fff',
      marginBottom: 10,
    },
    modalSubtitle: {
      fontSize: 14,
      color: '#ccc',
      marginBottom: 20,
    },
    datePickerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    dateSeparator: {
      marginHorizontal: 10,
      color: '#fff',
    },
    modalActions: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    modalButton: {
      padding: 10,
      backgroundColor: '#39BEB7',
      borderRadius: 5,
      color: '#fff',
    },
    paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 10,
      },
    activePage: {
        padding: 10,
        margin: 5,
        backgroundColor: '#39BEB7',
        color: '#fff',
        borderRadius: 5,
    },
    inactivePage: {
        padding: 10,
        margin: 5,
        backgroundColor: '#1A1D21',
        color: '#fff',
        borderRadius: 5,
    },
    navigationButton: {
        padding: 10,
        margin: 5,
        backgroundColor: '#39BEB7',
        color: '#fff',
        borderRadius: 5,
    },
    disabledPage: {
        padding: 10,
        margin: 5,
        backgroundColor: '#a0aec0',
        color: '#fff',
        borderRadius: 5,
    },
  });

export default TransactionHistory;