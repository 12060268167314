import React from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  StyleSheet,
  ScrollView,
} from "react-native";
import { FontAwesome6, FontAwesome5, FontAwesome } from '@expo/vector-icons';
import { ChargerAttributes } from "../models/charger-model";
import LoadingPlaceholder from "./LoadingPlaceholder";
import { getPriceInfo } from "./common/utils/get-price-info";

interface TimeAndDistance {
    chargerId: string;
    myDistance: string;
    myTime: string;
  }

const ChargerSelectMultiplePopup = (
    props: {
        selectedChargers: ChargerAttributes[],
        myTimeAndDistance: TimeAndDistance[],
        handleViewCharger: (charger: ChargerAttributes, index: number) => void,
    }
) => {

    return (
      <View>
        {/* Header / Multiple Chargers View */}
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Image
            source={require('../../assets/headerBarIcon.png')}
            style={{ width: 55, height: 5, marginTop: 12 }}
          />
        </View>

        {/* Content */}
        <View style={styles.contentContainer}>
          <ScrollView style={{ height: 320 }}>
            {props.selectedChargers.map((item, index) => {
              // getPriceInfo is assumed to return { currentPrice, priceLabel, nextTransition }
              const { currentPrice, priceLabel, nextTransition, todayPeak } = getPriceInfo(item);

              return (
                <View key={item.uuid}>
                  <View style={styles.row}>
                    {/* Left Icon */}
                    <View>
                      <Image
                        source={require('../../assets/stationPlugIn.png')}
                        style={[
                          styles.stationPlug,
                          {
                            tintColor: item.isOccupied === 1 ? 'red' : undefined,
                          },
                        ]}
                      />
                    </View>

                    {/* Charger Type Icon */}
                    <View>
                      <Image
                        source={require('../../assets/chargerType.png')}
                        style={styles.chargerType}
                      />
                    </View>

                    {/* Charger Details */}
                    <View style={styles.detailsContainer}>
                      <Text style={[styles.chargerName, styles.textColor]}>
                        {item.name}
                      </Text>
                      <Text style={styles.priceText}>
                        <FontAwesome name="dollar" size={14} color="#fff" />
                        {` ${currentPrice}/kWh`}
                          {todayPeak && todayPeak.start !== todayPeak.end && (
                            <Text style={styles.extraInfo}>{` (${priceLabel})`}</Text>
                          )}
                          {nextTransition && (
                            <Text style={styles.extraInfo}>{`\n[${nextTransition}]`}</Text>
                          )}
                      </Text>
                      <View style={styles.infoRow}>
                        <FontAwesome6 name="location-dot" size={17} color="#8D8E90" />
                        <Text style={styles.infoText}>
                          {props.myTimeAndDistance[index]?.myDistance !== undefined
                            ? `${props.myTimeAndDistance[index].myDistance} mi`
                            : <LoadingPlaceholder />}
                        </Text>
                        <FontAwesome5
                          name="car"
                          size={17}
                          color="#8D8E90"
                          style={{ marginLeft: 12 }}
                        />
                        <Text style={styles.infoText}>
                          {props.myTimeAndDistance[index]?.myTime !== undefined
                            ? `${props.myTimeAndDistance[index].myTime}`
                            : <LoadingPlaceholder />}
                        </Text>
                      </View>
                    </View>

                    {/* View Charger Button */}
                    <View>
                      <Pressable
                        style={styles.btn2}
                        onPress={() => props.handleViewCharger(item, index)}
                      >
                        <Text style={styles.btn2Text}>View Charger</Text>
                      </Pressable>
                    </View>
                  </View>

                  {/* Separator Line */}
                  {index < props.selectedChargers.length - 1 && (
                    <View style={[styles.line, { marginVertical: 16 }]} />
                  )}
                </View>
              );
            })}
          </ScrollView>
        </View>
      </View>
    );
}

export default ChargerSelectMultiplePopup;

const styles = StyleSheet.create({
  contentContainer: {
    padding: 16, // equivalent to p-4
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stationPlug: {
    width: 30,
    height: 38,
    marginLeft: 6,
    marginRight: 4
  },
  chargerType: {
    width: 40,
    height: 40,
  },
  detailsContainer: {
    flex: 1,
    marginHorizontal: 8,
  },
  chargerName: {
    fontSize: 12,
  },
  priceText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'white',
    marginTop: 4,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  infoText: {
    color: '#8D8E90',
    marginLeft: 4,
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  btn1: {
    borderColor: '#8D8E90',
    borderWidth: 2,
    paddingHorizontal:24,
    paddingVertical: 12,
    borderRadius: 30,
    backgroundColor: 'transparent'
  },
  btn2: {
    backgroundColor: '#39BEB7', 
    borderRadius: 30, 
    paddingHorizontal:8, 
    paddingVertical: 12
  },
  btn2Text: {
    fontWeight: '500',
    color: 'white',
  },
  textColor: {
    color: '#E8E8E9'
  },
  extraInfo: {
    fontSize: 12, // quite a bit smaller than the main price text
    color: '#39BEB7',
  },
});