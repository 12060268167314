import React, { useState, useEffect, useRef } from "react";
import { View, Text, Pressable, StyleSheet, Image, Linking, Animated } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { UserSessionAttributes } from "../models/user-session-model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { updateUser } from "../store/actions/user-actions";

interface Props {
  className?: string;
}

const UserModeSelect: React.FC<Props> = ({ className }) => {
    const userSession:UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
    const [userMode, setUserMode] = useState<string>(userSession?.primary_use ?? 'user');
    const driverOpacity = useRef(new Animated.Value(userSession?.primary_use === 'driver' ? 1 : 0.5)).current; // Opacity controls for driver button
    const hostOpacity = useRef(new Animated.Value(userSession?.primary_use === 'host' ? 1 : 0.5)).current; // Opacity controls for host button
    const tintColor='white';
    const dispatch: AppDispatch = useDispatch();
    
    if(userSession?.primary_use === 'guest') return null;

    const toggleUserMode = (mode: string) => {
        console.log(`Toggling mode: ${mode}`);

        if(userSession !== undefined){
            dispatch(updateUser(userSession, { 
                primary_use: mode 
            }));
        }

        setUserMode(mode);

        // Animate fade effect when switching modes
        Animated.timing(mode === 'driver' ? driverOpacity : hostOpacity, {
            toValue: 1, // Fully visible
            duration: 300, // Smooth transition
            useNativeDriver: true,
        }).start();

        Animated.timing(mode === 'driver' ? hostOpacity : driverOpacity, {
            toValue: 0.5, // Dim the inactive button
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const openWaitlistWebsite = () => {
        Linking.openURL('https://meanderev.com/waitlist/');
    };

    return (
        <View style={styles.container} className={className}>
        {userSession?.role === "user" ? (
            <View style={styles.contentContainer}>
            <View style={styles.textContainer}>
                <Text
                    style={styles.text}
                    numberOfLines={2}
                    adjustsFontSizeToFit
                >
                    Interested in monetizing your EV charger?
                </Text>
            </View>
            <Pressable 
                style={styles.waitlistButton}
                onPress={openWaitlistWebsite}
            >
                <Text style={styles.buttonText}>
                    JOIN WAITLIST
                </Text>
            </Pressable>
            </View>
        ) : (
            <View style={styles.contentContainer}>
                <View style={styles.toggleTextContainer}>
                    <Text style={styles.textUserMode}>
                        User Mode:
                    </Text>
                </View>
                <View style={styles.buttonGroup}>
                    {/* DRIVER BUTTON */}
                    <Animated.View style={[styles.animatedButton, { opacity: driverOpacity }]}>
                        <Pressable 
                            style={[
                                    styles.modeButton, 
                                    styles.firstModeButton, 
                                    userMode === 'driver' ? null : styles.inactiveButton
                                ]}
                            onPress={() => toggleUserMode('driver')}
                        >
                            <Image
                                source={require("../images/carNonHightighted.png")}
                                resizeMode="cover"
                                style={[{
                                    height: 22,
                                    width: 25,
                                    margin: 10
                                },
                                    tintColor ? { tintColor } : null
                                ]}
                            />
                            <Text style={styles.toggleButtonText}>
                                DRIVER
                            </Text>
                        </Pressable>
                    </Animated.View>
                    {/* HOST BUTTON */}
                    <Animated.View style={[styles.animatedButton, { opacity: hostOpacity }]}>
                        <Pressable style={[
                                styles.modeButton, 
                                styles.secondModeButton,
                                userMode === 'host' ? null : styles.inactiveButton
                            ]}
                            onPress={() => toggleUserMode('host')}
                        >
                            <Image
                                source={require("../images/pumpNonHighlighted.png")}
                                resizeMode="cover"
                                style={[{
                                    height: 25,
                                    width: 20,
                                    margin: 10
                                },
                                    tintColor ? { tintColor } : null
                                ]}
                            />
                            <Text style={styles.toggleButtonText}>
                                HOST
                            </Text>
                        </Pressable>
                    </Animated.View>
                </View>
            </View>
        )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        marginHorizontal: -32, // cancels out 16 pixels on each side
        marginTop: 20,
        height: 64,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderWidth: 0.5,
        borderColor: "#376F78",
        paddingHorizontal: 10,
        alignSelf: "center",
      },
      contentContainer: {
        flexDirection: "row",
        alignItems: "center", // vertically center children
        justifyContent: "space-between",
        flex: 1, // ensure this container fills the parent's height
      },
  textContainer: {
    flex: 1,
    paddingRight: 40,
    marginLeft: 23,
    fontFamily: 'PlusJakartaSans-Regular',
  },
  toggleTextContainer: {
    flex: 1,
    paddingRight: 15,
    marginLeft: 23,
    fontFamily: 'PlusJakartaSans-Regular',
  },
  text: {
    color: "#FFFFFF",
    fontSize: 15,
    flexWrap: "wrap",
    fontFamily: 'PlusJakartaSans-Regular',
  },
  textUserMode: {
    color: "#FFFFFF",
    fontSize: 16,
    flexWrap: "wrap",
    fontFamily: 'PlusJakartaSans-Regular',
  },
  waitlistButton: {
    backgroundColor: "#40BFB4",
    height: 40,
    minWidth: 134,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    paddingHorizontal: 10,
    marginRight: 23,
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 12,
    textAlign: "center",
    fontFamily: 'PlusJakartaSans-Regular',
  },
  toggleButtonText: {
    color: "#FFFFFF",
    fontSize: 11,
    textAlign: "center",
    fontFamily: 'PlusJakartaSans-Regular',
  },
  buttonGroup: {
    flex: 3,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 23,
  },
  modeButton: {
    flex: 2.5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#40BFB4",
    height: 40,
    borderRadius: 210,
    paddingHorizontal: 10,
  },
  inactiveButton: {
    backgroundColor: "#AEAEB2",
  },
  // Apply a margin to create a gap between the buttons.
  firstModeButton: {
    marginRight: 5,
  },
  secondModeButton: {
    marginLeft: 5,
  },
  animatedButton: {
    flex: 1, // Ensures both buttons are equal width
}
});

export default UserModeSelect;