import React from 'react';
import { View, Button, Platform, Image, Text, Pressable} from 'react-native';
// import { GoogleSignin, statusCodes } from '@react-native-google-signin/google-signin';
import { makeRedirectUri, useAuthRequest, ResponseType } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';

WebBrowser.maybeCompleteAuthSession();

interface GoogleAuthButtonProps {
  navigation: any; // Update with your specific navigation type if using react-navigation typings
  RBRef: React.RefObject<any>; // Replace `any` with the specific type for your bottom sheet ref
  destination: string;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  navigation, 
  RBRef,
  destination}) => {

  const redirectUri = makeRedirectUri({
    useProxy: true, // For managed workflow, useProxy enables easier setup
    //path: process.env.REACT_NATIVE_OAUTH2_GOOGLE_INIT_URL,
    //native: 'http://app.meanderev.co:8803/api/oauth2/callback'
    path: process.env.REACT_NATIVE_MEANDER_REDIRECT_URI,
    //scheme: 'meanderapp',
    //path: '/api/oauth2/callback'
  });
  
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: process.env.REACT_NATIVE_GOOGLE_OAUTH2_WEB_CLIENT!,
      responseType: ResponseType.Code,
      scopes: ['email', 'profile'],
	    redirectUri,
    },
     {authorizationEndpoint: `${process.env.REACT_NATIVE_API_BASE_URL}oauth2/init/web`}
  );

  React.useEffect(() => {
    console.log("Got a response: ", response)
    if(response?.type === 'dismiss'){
      RBRef.current.close();
    }
  }, [response]);

  return (
    <Pressable
        disabled={!request}
        onPress={() => {
          promptAsync({useProxy: true})
      }}
      className='rounded-full'
      >
        <View className="flex-row items-center justify-center px-4 py-3 my-1 bg-white rounded-full">
          <Image
            source={require("../../assets/googleIcon.png")}
            style={{
              width: 24,
              height: 24,
            }}
            resizeMode='contain' // Ensures the image scales properly without being cropped
          />
          <Text className="ml-4 text-lg font-medium text-black">
            Continue with Google
          </Text>
        </View>
    </Pressable>
    );
}

export default GoogleAuthButton;