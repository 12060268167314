import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  SafeAreaView,
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Pressable,
  Platform,
  Linking,
  ActivityIndicator,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome6 } from "@expo/vector-icons";
import { ChargerAttributes } from "../models/charger-model";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import SignInOptions from "./SignInOptions";
import { OrderAttributes } from "../models/order-model";
import { UserSessionAttributes } from "../models/user-session-model";

const { width, height } = Dimensions.get("window");

type ChargingStationNotFoundNavigationProps = NativeStackScreenProps<
  RootStackParamList,
  "ChargingStationNotFound"
>;

export const ChargingStationNotFound: React.FC<ChargingStationNotFoundNavigationProps> = ({
  navigation,
  route,
}) => {

  const dispatch: AppDispatch = useDispatch();

  const session: UserSessionAttributes | undefined = useSelector(
    (state: RootState) => state.user.session
  );

  const scrollViewRef = useRef<ScrollView>(null);

  const selectedCharger: ChargerAttributes = useSelector(
    (state: RootState) => state.chargers.selectedCharger
  );
  
  return (
    <SafeAreaView style={styles.safeArea}>
      <View
        style={[
          styles.containerBgColor,
          { height: height - 65, paddingBottom: 10, flex: 1 },
        ]}
      >
        <ScrollView ref={scrollViewRef}>
          <Image
            source={require("../../assets/ChargerStationBg.png")}
            style={{ aspectRatio: 1 }}
            alt="Background Image"
          />
          <View style={styles.container}>
            <Text style={styles.headingText}>
              {`Sorry, we couldn't find your charger:\n`}
              <Text style={styles.chargerSerial}>
                {`${route.params.chargerSerial}`}
              </Text>
            </Text>
            <Text style={styles.supportText}>
              Please contact MeanderEV support if you have received this message in error
            </Text>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#0B1E1D",
  },
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  container: {
    marginHorizontal: 16, 
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  headingText: {
    fontSize: 18,
    color: "#51B868",
    fontWeight: "bold",
    textAlign: "center",
  },
  chargerSerialText: {
    color: "#39BEB780",
  },
  chargerSerial: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#39BEB780",
    textAlign: "center",
  },
  supportText: {
    fontSize: 14,
    color: "white",
    textAlign: "left",
    marginTop: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0B1E1D", // Light background for better contrast
  },
});

export default ChargingStationNotFound;
