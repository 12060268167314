export interface ChargerAttributes {
    uuid: string | undefined;
    manufacturer_name?: string;
    charger_model?: string;
    unit_serial: string;
    meander_serial?: string;
    charger_type?: string;
    plug_type?: string;
    description?: string;
    environment?: string;
    address?: string;
    provider?: string;
    charging_capacity?: number;
    install_date?: Date; // Assuming install_date is in the format YYYY-MM-DD
    contact_phone?: string;
    contact_email?: string;
    access_instructions?: string;
    availability_times?: string;
    booked_times?: string;
    isOccupied?: number;
    power_provider?: number;
    grace_period?: number;
    registered_user: string;
    total_uptime: number;
    lifetime_kwh: number;
    manufacturer_device_id: string;
    lat: number;
    lng: number;
    price_hourly: number;
    cost_est_kwh: number;
    name: string;
    currency: string;
    updatedAt: Date;
    createdAt: Date;
    full_address: string;
    street_number: string;
    street_name: string;
    zip_code: string;
    city: string;
    state: string;
    country: string;
    price_hourly_peak: number;
    price_hourly_off_peak: number;
    price_kwh_peak: number;
    price_kwh_off_peak: number;
    cost_est_kwh_peak: number;
    cost_est_kwh_off_peak: number;
    peak_selection: string;
    isSelected: boolean;
    status: string | null;
    totalDistance?: number;
    is_enabled: boolean;
}

export const createEmptyCharger = (overrides?: Partial<ChargerAttributes>): ChargerAttributes => ({
    uuid: '',
    manufacturer_name: '',
    charger_model: '',
    unit_serial: '',
    meander_serial: '',
    charger_type: '',
    plug_type: '',
    description: '',
    environment: '',
    address: '',
    provider: '',
    charging_capacity: 0,
    install_date: new Date(),
    contact_phone: '',
    contact_email: '',
    access_instructions: '',
    availability_times: '',
    booked_times: '',
    isOccupied: 0,
    power_provider: 0,
    grace_period: 0,
    registered_user: '',
    total_uptime: 0,
    lifetime_kwh: 0,
    manufacturer_device_id: '',
    lat: 0,
    lng: 0,
    price_hourly: 0,
    cost_est_kwh: 0,
    currency: '',
    updatedAt: new Date(),
    createdAt: new Date(),
    name: '',
    price_hourly_peak: 0,
    price_hourly_off_peak: 0,
    price_kwh_peak: 0,
    price_kwh_off_peak: 0,
    cost_est_kwh_peak: 0,
    cost_est_kwh_off_peak: 0,
    peak_selection: '',
    full_address: '',
    street_number: '',
    street_name: '',
    zip_code: '',
    city: '',
    state: '',
    country: '',
    isSelected: false,
    status: null,
    totalDistance: 0,
    is_enabled: true,
    ...overrides
});

export type ChargerManufacturerInfo = {
    name: string | undefined,
    charge_rate_amps: number
    vehicle_connected: boolean,
    vehicle_charging: boolean,
    manufacturer: string
}

export type ChargerUsageData = {
    peakTimeSeconds: number,
    offPeakTimeSeconds: number,
    peakKwh: number,
    offPeakKwh: number
}

export type ActiveChargeStatus = {
    chargerCarData: ChargerManufacturerInfo,
    usageData: ChargerUsageData,
    peakPrice: number,
    offPeakPrice: number,
    message: string
}