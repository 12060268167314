import React, { Component, useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import {debounce, initial} from 'lodash';

import loadGoogleMapsAPI from "./WebMapComponent";

import MapView, {MapViewProps, Marker, Polyline} from '@preflower/react-native-web-maps'
import { ChargerAttributes } from "../models/charger-model";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import * as Location from 'expo-location';
import { notificationService } from "../services/notification-service";

// import availableCharger from '../../assets/availableCharger.png';
// import unAvailableCharger from '../../assets/unavailableCharger.png';
// import selectedChargerMap from '../../assets/selectedCharger.png';

import AvailableChargerIcon from '../../assets/ChargerAvailableIcon.png';
import UnAvailableChargerIcon from '../../assets/ChargerUnavailableIcon.png';
import SelectedChargerIcon from '../../assets/ChargerSelectedIcon.png';

import currentLocationIcon from '../../assets/currentLocation.png';
import { PanGestureHandler, State } from "react-native-gesture-handler";
import { getUserLocation } from "../components/common/utils/get-user-location";

//let MapViewMob: typeof MapView | undefined, MarkerMob: typeof Marker | undefined;

let MapViewMob: any, MarkerMob: any, MapViewDirectionsMob: any;
// if (Platform.OS === "android" || Platform.OS === "ios") {
//   MapViewMob = require("react-native-maps").default;
//   MarkerMob = require("react-native-maps").Marker;
//   MapViewDirectionsMob = require("react-native-maps-directions").default;
// }
const { width, height} = Dimensions.get('window');

//const availableChargerIcon = <Image source={AvailableChargerIcon} style={{ width: 32, height: 32 }} />;

const defaultLatitude = 39.7392;
const defaultLongitude = -104.9903

const initialCoordinates: Location.LocationObjectCoords = {
  latitude: 39.7392, // Default to Denver, CO
  longitude: -104.9903,
  accuracy: 0,
  altitude: 0,
  altitudeAccuracy: 0,
  heading: 0,
  speed: 0,
}

const MapScreen = (props: {
  markerOpenBottomSheet: (charger: ChargerAttributes) => void,
  markerCloseBottomSheet: () => void,
  myInputLocation: Location.LocationObjectCoords,
  selectCharger: any,
  onMapInteraction: (interact: boolean) => void,
}) => {
  //const [coords, setCoords] = useState<Array<{ latitude: number; longitude: number }>>([]);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);
  const [origin, setOrigin] = useState<Location.LocationObjectCoords | null>({ 
    ...props.myInputLocation, 
    latitude: props.myInputLocation?.latitude ?? initialCoordinates.latitude, 
    longitude: props.myInputLocation?.longitude ?? initialCoordinates.longitude });
  const [destination, setDestination] = useState<Location.LocationObjectCoords | null>({ 
    ...props.myInputLocation, 
    latitude: props.myInputLocation?.latitude ?? defaultLatitude, 
    longitude: props.myInputLocation?.longitude });
  const [selectedCharger, setSelectedCharger] = useState<any>();
  const [isMapInteraction, setIsMapInteraction] = useState(false);
  const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(props.myInputLocation);
  //const [waypoint] = useState<Coordinates>({ latitude: 33.8589565, longitude: -117.9589782 });

  const [myCurrentLocation, setMyCurrentLocation] = React.useState<Location.LocationObjectCoords>(props.myInputLocation);

  const chargerList: ChargerAttributes[] = useSelector(
    (state: RootState) => state.chargers.chargers
  ) as ChargerAttributes[];

  useEffect(() => {
    //console.log("mapScreen useEffect triggered");
    if (Platform.OS === "web") {
      loadGoogleMapsAPI(() => {
        setGoogleMapsLoaded(true);
      });
    }

    const getLocation = async () => {
      const userLocation = await getUserLocation();
    
      // Update state and trigger notification only if the location has changed
      setMyCurrentLocation((prevLocation) => {
        if (
          prevLocation &&
          prevLocation.latitude === userLocation.latitude &&
          prevLocation.longitude === userLocation.longitude &&
          prevLocation.accuracy === userLocation.accuracy
        ) {
          return prevLocation; // No change, return the same state
        }
    
        // Trigger notification if accuracy is 0 and state is about to change
        if (userLocation.accuracy === 0) {
          notificationService.sendNotification("info", "No location info given!");
        }
    
        return userLocation; // Update with new location
      });
    };
    
    getLocation();

  }, []);

  useEffect(() => {
    if (!myLocation) return;
    setOrigin(myLocation);
    setDestination(myLocation);
  }, [myLocation])

  const filteredChargers = useMemo(() => {
    const validChargers = chargerList?.filter(
      (charger) => charger.lat !== null && 
                    charger.lng !== null &&
                    charger.is_enabled === true
    );
  
    if (Array.isArray(validChargers) && validChargers.length > 0) {
      return validChargers.map((selectCharger) => {
        if (selectCharger.uuid === selectedCharger?.uuid) {
          return { ...selectCharger, isSelected: props?.selectCharger };
        }
        return { ...selectCharger, isSelected: false };
      });
    }
  
    return [];
  }, [chargerList, props?.selectCharger, selectedCharger]);

  //const {latitude, longitude} = myCurrentLocation

  const handleMarkerPress = useCallback(
    (charger: ChargerAttributes) => {
      // Check if the marker is not the user's location
      if (
        !(
          charger.lat === props.myInputLocation?.latitude &&
          charger.lng === props.myInputLocation?.longitude
        )
      ) {
        props?.markerOpenBottomSheet(charger);
      }
      setSelectedCharger(charger);
    },
    [props.myInputLocation, props.markerOpenBottomSheet] // Add dependencies here
  );
  
  //build marker list for map
  const markerList = useMemo(
    () =>
      filteredChargers.map((charger) => (
        <Marker
          key={charger.uuid}
          coordinate={{ latitude: charger.lat!, longitude: charger.lng! }}
          title={charger.name}
          icon={
            charger?.isOccupied !== 1 &&
            (charger?.isSelected === undefined || charger?.isSelected === false)
              ? AvailableChargerIcon
              : charger?.isSelected === true
              ? SelectedChargerIcon
              : UnAvailableChargerIcon
          }
          onPress={() => handleMarkerPress(charger)}
          zIndex={2}
        />
      )),
    [filteredChargers, handleMarkerPress]
  );

  // Create a debounced version of onRegionChange using useCallback
  const debouncedOnRegionChange = useCallback(
    debounce((newRegion: any) => {
      if (
        myLocation &&
        newRegion.latitude === myLocation.latitude &&
        newRegion.longitude === myLocation.longitude
      ) {
        return; // Prevent redundant updates
      }
  
      setMyLocation(newRegion);
    }, 10),
    [myLocation]
  );

  const onRegionChangeComplete = useCallback((region) => {
    //console.log("Region changed:", region);
  }, []);

  const onPress = (event) => {
    //console.log("Map pressed:", event.nativeEvent.coordinate);
  };

  const onDoublePress = (event) => {
    //console.log("Map double pressed:", event.nativeEvent.coordinate);
  };

  const onPanDrag = () => {
    //console.log("Map panned or dragged");
  };
  //const { origin, destination, googleMapsLoaded, coords } = this.state;

  const onGestureEvent = (event: any) => {
    if (event.nativeEvent.state === State.BEGAN) {
      // Gesture has started, meaning user is interacting with the map
      console.log("beginning swipe");
      setIsMapInteraction(true);
    } else if (event.nativeEvent.state === State.END || event.nativeEvent.state === State.CANCELLED) {
      // Gesture has ended or been cancelled, allow TabView to handle gestures again
      setIsMapInteraction(false);
    }
  };

  const initialRegion = useMemo(
    () => ({
      latitude: myCurrentLocation.latitude,
      longitude: myCurrentLocation.longitude,
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0421,
    }),
    [myCurrentLocation]
  );

    return(
        <View style={[styles.container, {height: height}]}>
          {googleMapsLoaded && Platform.OS === "web" ? (
            <View style={styles.container}>
              <PanGestureHandler 
              onGestureEvent={onGestureEvent}
              onHandlerStateChange={onGestureEvent}
              minDist={10} // This can be adjusted based on sensitivity needed
            >
              <MapView
                style={styles.map}
                initialRegion={initialRegion}
                onRegionChange={(new_region) => {
                  props.onMapInteraction(true);
                  debouncedOnRegionChange(new_region);
                }}
                onRegionChangeComplete={onRegionChangeComplete}
                onPress={onPress}
                onDoublePress={onDoublePress}
                onPanDrag={onPanDrag}
                zoomEnabled={true}
                zoomControlEnabled={true}
                mapType="terrain"
                showsPointsOfInterest={false}
                showsUserLocation={false}
                showsMyLocationButton={true}
                followUserLocation
              >
                {/* <Marker coordinate={myLocation} pinColor="blue" /> */}
              {markerList}
              {props.myInputLocation.accuracy !== 0 &&
                <Marker 
                  key={`myLocationMarker`}
                  coordinate={{
                    latitude: props.myInputLocation?.latitude ?? 0, 
                    longitude: props.myInputLocation?.longitude ?? 0
                  }} //typescript not null assertion !
                  title={'Current Location'} 
                  icon={currentLocationIcon}
                  zIndex={1}
                />
              }
  
                {/* {coords && (
                  <MapView.Polyline
                    // coordinates={coords.map((coord) => ({
                    //   latitude: coord[0],
                    //   longitude: coord[1],
                    // }))}
                    coordinates={coords}
                    strokeWidth={8}
                    strokeColor="royalblue"
                    tappable={true}
                    onClick={() => {
                      onPolylineClicked();
                    }}
                  />
                )} */}
              </MapView>
              </PanGestureHandler>
            </View>
          ) : Platform.OS === "android" || Platform.OS === "ios" ? (
            <View style={styles.container}>
              <MapViewMob
                style={styles.map}
                onRegionChange={(new_region) => {
                  debouncedOnRegionChange(new_region);
                }}
              >
                <MarkerMob coordinate={origin} title="Origin">
                  <View style={styles.markerContainer}></View>
                </MarkerMob>
  
                <MarkerMob coordinate={destination} title="Destination">
                  <View style={styles.markerContainer}></View>
                </MarkerMob>
  
                {/* {markers &&
                  markers.map((marker, index) => (
                    <MarkerMob
                      key={index}
                      coordinate={marker.latlng}
                      title={marker.title}
                      description={marker.description}
                    />
                  ))} */}
  
                {plot.draw && (
                  <MapViewDirectionsMob
                    origin={origin}
                    destination={destination}
                    waypoint={plot.waypoint}
                    strokeColor={showIcon ? "red" : "royalblue"}
                    tappable={true}
                    onPress={() => {
                      onPolylineClicked();
                    }}
                    apikey={apiKey}
                    strokeWidth={14}
                  />
                )}
              </MapViewMob>
            </View>
          ) : (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#39BEB7" />
              <Text style={styles.loadingText}>Loading map...</Text>
            </View>
          )}
        </View>
      );
  }

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  map: {
    ...StyleSheet.absoluteFillObject,
    borderColor: "white",
    // borderWidth: 8,
    // borderTopWidth: 4,
    // borderBottomWidth: 4,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0B1E1D", // Light background for better contrast
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: "#666",
    textAlign: "center",
  },
  markerContainer: {
    width: 40,
    height: 40,
  },
  markerImage: {
    flex: 1,
    width: undefined,
    height: undefined,
  },

  rgnText: {
    fontSize: 12,
    color: "#666666",
  },
  rgnView: { flexDirection: "row", alignItems: "flex-end" },
  button: {
    backgroundColor: "orange",
    padding: 8,
    margin: 5,
    borderRadius: 5,
    alignItems: "center",
    height: 35,
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
});

export default React.memo(MapScreen);