import { ChargerAttributes } from "../../../models/charger-model";
import { generatePeakObj } from "./peak-off-peak-field";

// A helper function to convert a 24-hour integer to a 12-hour string with AM/PM.
const formatHour = (hour: number) => {
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12}${period}`;
};

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const dayAbbreviations: Record<string, string> = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tues',
    Wednesday: 'Wed',
    Thursday: 'Thurs',
    Friday: 'Fri',
    Saturday: 'Sat',
};

interface PeakSchedule {
    start: number;
    end: number;
}

  

export const getPriceInfo = (charger: ChargerAttributes) => {
    const peakData = generatePeakObj(charger.peak_selection);

    // Get current date and hour
    const now = new Date();
    const currentHour = now.getHours();
    // Map day index (0-6) to day name
    
    const todayName = days[now.getDay()];
    const todayPeak = peakData[todayName];

    let currentPrice;
    let priceLabel = '';
    let nextTransition = '';

    // If both prices exist and today has a defined peak period (i.e. start and end are not equal)
    if (todayPeak && todayPeak.start !== todayPeak.end) {
        if (currentHour >= todayPeak.start && currentHour < todayPeak.end) {
            // We are in the peak period.
            currentPrice = charger.price_kwh_peak;
            priceLabel = 'pk';
            nextTransition = `next off-pk at ${formatHour(todayPeak.end)}`;
        } else {
            // Off-peak period.
            currentPrice = charger.price_kwh_off_peak;
            priceLabel = 'off-pk';
            // Determine the next peak transition.
        if (currentHour < todayPeak.start) {
            // Peak hasn't started yet today.
            nextTransition = `next pk at ${formatHour(todayPeak.start)}`;
        } else {
            // Today's peak period is over.
            // Look up tomorrow's peak period.
            const tomorrow = new Date(now);
            tomorrow.setDate(now.getDate() + 1);
            const tomorrowName = days[tomorrow.getDay()];
            const tomorrowPeak = peakData[tomorrowName];
            if (tomorrowPeak && tomorrowPeak.start !== tomorrowPeak.end) {
            nextTransition = `pk at ${formatHour(tomorrowPeak.start)} (tomorrow)`;
            }
        }
        }
    } else {
        // Fallback: if only one price is available or no valid peak period is set for today.
        currentPrice = charger.price_kwh_off_peak;
    }

    return { currentPrice, priceLabel, nextTransition, todayPeak };
}



/**
 * getRateTransitions
 *
 * This helper examines the current time and the provided peak schedule (peakData)
 * to return extra text for both the Peak and Off‑Peak rate rows.
 *
 * For each row:
 * - If the rate is currently active, it returns a string like "until 6PM".
 * - If it’s not active, it returns a string like "next at 5PM on Monday".
 *
 * Note that if no valid peak schedule exists for today (for example, Sunday with {start:0, end:0}),
 * then peak-related information will be empty.
 */
interface RateTransition {
    peakTransition: string;
    offPeakTransition: string;
}

export const getRateTransitions = (charger: ChargerAttributes): RateTransition => {

    const peakData = generatePeakObj(charger.peak_selection);
    
    const now = new Date();
    const currentHour = now.getHours();
    const todayName = days[now.getDay()];
    const todaySchedule = peakData[todayName];
  
    // Helper: Look for the next day (within the next 7 days) that has a valid peak schedule.
    const findNextValidSchedule = (
        date: Date
    ): { dayName: string; schedule: PeakSchedule } | null => {
        for (let i = 1; i <= 7; i++) {
          const nextDate = new Date(date);
          nextDate.setDate(date.getDate() + i);
          const nextDayName = days[nextDate.getDay()];
          const schedule = peakData[nextDayName];
          if (schedule && schedule.start !== schedule.end) {
            return { dayName: nextDayName, schedule };
          }
        }
        return null;
      };
  
    let peakTransition = "";
    let offPeakTransition = "";
  
    // Determine if today's schedule is valid (e.g. Sunday might have {start: 0, end: 0})
    const validSchedule = todaySchedule && todaySchedule.start !== todaySchedule.end;
  
    // --- Peak rate transition info ---
    // Only calculate if a peak price is provided and today has a valid peak period.
    if (charger.price_kwh_peak && validSchedule) {
        if (currentHour < todaySchedule.start) {
          // Upcoming peak is later today.
          peakTransition = `next: ${dayAbbreviations[todayName]} at ${formatHour(todaySchedule.start)}`;
        } else if (currentHour >= todaySchedule.start && currentHour < todaySchedule.end) {
          // Peak is active.
          peakTransition = `until ${formatHour(todaySchedule.end)}`;
        } else {
          // Today's peak is over; look for the next valid schedule.
          const next = findNextValidSchedule(now);
          if (next) {
            peakTransition = `next: ${dayAbbreviations[next.dayName]} at ${formatHour(next.schedule.start)}`;
          }
        }
      }
  
    // --- Off-Peak rate transition info ---
    // Off-peak pricing is always provided. If there is a valid peak schedule,
    // then off-peak periods are defined by the start time of the next peak period.
    if (charger.price_kwh_off_peak) {
      if (validSchedule) {
        if (currentHour < todaySchedule.start) {
          // Off-peak is active until peak starts later today.
          offPeakTransition = `until ${formatHour(todaySchedule.start)}`;
        } else if (currentHour >= todaySchedule.start && currentHour < todaySchedule.end) {
          // During peak hours, off-peak isn’t active, so show when it will resume.
          offPeakTransition = `next: ${dayAbbreviations[todayName]} at ${formatHour(todaySchedule.end)}`;
        } else {
          // After today's peak, off-peak is active until the next peak period.
          const next = findNextValidSchedule(now);
          console.log("received next offpeak obj");
          console.log(next);
          if (next) {
            offPeakTransition = `until ${formatHour(next.schedule.start)} on ${dayAbbreviations[next.dayName]}`;
          }
        }
      }
      // If there's no valid peak schedule, off-peak is always in effect and no extra text is needed.
    }
  
    return {
      peakTransition,
      offPeakTransition,
    };
  };